<template>
  <v-container fluid>
    <!-- <v-row>
      <v-col class="d-flex justify-end">
        <v-btn text color="#00CF46" class="ml-0" @click="backtoCampaign()">
          &lt; Back to campaign
        </v-btn>
      </v-col>
    </v-row> -->
    <v-card class="pa-4 my-2 d-block d-sm-flex justify-space-between elevation-2" two-line
      style="background-color: #ec9e98;">
      <div class="d-flex align-center customBorderRadius">
        <v-btn color="#913a33" fab small>
          <v-icon color="#ec9e98">mdi-account-alert</v-icon>
        </v-btn>
        <p class="mb-0 mx-3">
          Our voice broadcast service is currently undergoing maintenance to implement the new TRAI guidelines. We will
          have it up and running again as soon as the process is complete.
        </p>
      </div>


      <!-- <div class="d-flex flex-row-reverse">
        <v-btn
          color="primarygrad"
          style="color: white;"
          @click="$router.push({ path: '/organization' })"
        >
          KYC Verification
        </v-btn>
      </div> -->
    </v-card>
    <v-tabs v-model="activeTab" background-color="primary" centered dark>
      <v-tab>Campaign Settings</v-tab>
      <v-tab v-if="$route.params.hasOwnProperty('id')">Reports</v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab">
      <v-tab-item>
        <v-card class="pa-5" outlined>
          <!-- Campaign Settings -->
          <!-- <v-card-title class="justify-space-between">
            Campaign Settings
          </v-card-title> -->

          <v-row>
            <!-- Left Column -->
            <v-col cols="12" md="6">
              <!-- Dropdown for Choosing List -->
              <div>Campaign Name</div>
              <v-text-field v-model="campaignName" type="text" outlined dense class="mb-5"
                :disabled="existingCampaign != null"></v-text-field>

              <div>Choose List</div>
              <v-autocomplete v-model="getSelectedList" class="pt-1" :items="lists" item-text="name" item-value="id"
                autocomplete="false" prepend-inner-text="view_list" hide-details dense background-color="inverted"
                outlined :disabled="existingCampaign != null" flat @change="calculateCredits" />

              <div style="font-size: 18px;padding-top: 10px;">
                Total number of contacts : {{ totalContacts }}
              </div>

              <!-- Radio Buttons for Broadcast Content -->
              <v-radio-group v-model="broadcastType" label="Broadcast Content" class="mb-2"
                :disabled="existingCampaign != null">
                <v-radio label="Audio" value="audio" :disabled="true"></v-radio>
                <v-radio label="Text" value="text" :disabled="true"></v-radio>
                <v-radio v-if="approvedAudioList != null && approvedAudioList.length > 0"
                  label="Choose from approved files" value="audioList"></v-radio>
              </v-radio-group>

              <!-- Input for approved audio file  -->

              <div v-if="broadcastType == 'audioList'" class="mt-2 mb-4">
                <v-autocomplete v-model="selectedApprovedFile" :items="approvedAudioList" item-text="fileName"
                  item-value="id" label="Select Audio List" persistent-hint return-object single-line hide-details dense
                  background-color="inverted" outlined @change="changeSelectedAudio"></v-autocomplete>
              </div>

              <div v-if="broadcastType == 'audioList'">
                <div class="mb-2">
                  <span>File Choosen:</span>
                </div>
                <div class="d-flex align-center audio-container">
                  <div class="audio-left">
                    <span class="mr-2 custom-font">{{
                      selectedApprovedFile.fileName
                    }}</span>
                  </div>
                  <div class="d-flex align-center audio-right">
                    <v-btn icon @click="togglePlay">
                      <v-icon>
                        {{ isPlaying ? "mdi-pause" : "mdi-play" }}
                      </v-icon>
                    </v-btn>
                    <v-progress-linear :value="progress" class="rounded-lg" color="blue" striped
                      height="10"></v-progress-linear>
                    <span class="ml-2">{{ timeLeft }}</span>
                  </div>
                </div>
              </div>

              <div v-if="broadcastType == 'text'">
                <div>Enter Text</div>
                <v-textarea v-model="inputText" class="mb-5 pt-1" dense background-color="inverted" outlined
                  :disabled="existingCampaign != null" flat></v-textarea>
              </div>

              <!-- File Upload for Audio Message -->

              <!-- <v-file-input v-if="existingCampaign == null && broadcastType != 'text'"
                label="Click to upload audio file" prepend-icon="mdi-upload" accept="audio/wav" outlined dense
                class="mb-5 cursor" :rules="rules" @change="onFileSelect"></v-file-input> -->

              <div v-if="
                existingCampaign == null &&
                broadcastType != 'text' &&
                broadcastType != 'audioList'
              ">
                <v-btn color="primary" outlined class="ma-0" @click="triggerFileInput">
                  <v-icon left>mdi-upload</v-icon>
                  Click to upload audio file
                </v-btn>
                <input ref="fileInput" type="file" accept="audio/wav" style="display: none;" @change="onFileSelect" />
                <v-text-field v-if="selectedFileName" v-model="selectedFileName" label="Selected File" readonly outlined
                  dense class="mt-3"></v-text-field>
              </div>

              <div v-if="existingCampaign" class="mb-5 pt-1">
                <div class="mb-2">
                  <span>File Choosen:</span>
                </div>
                <div class="d-flex align-center audio-container">
                  <div class="audio-left">
                    <span class="mr-2 custom-font">{{
                      existingCampaign.fileName
                    }}</span>
                  </div>
                  <div class="d-flex align-center audio-right">
                    <v-btn icon @click="togglePlay">
                      <v-icon>
                        {{ isPlaying ? "mdi-pause" : "mdi-play" }}
                      </v-icon>
                    </v-btn>
                    <v-progress-linear :value="progress" class="rounded-lg" color="blue" striped
                      height="10"></v-progress-linear>
                    <span class="ml-2">{{ timeLeft }}</span>
                  </div>
                </div>
              </div>

              <div v-if="existingCampaign" class="mt-4">
                <div class="mb-2">
                  <span>File approval status:
                    <span :class="fileStatusClass">{{
                      existingCampaign.audio.approved ? "APPROVED" : "PENDING"
                    }}</span></span>
                </div>
              </div>
              <div v-if="existingCampaign && existingCampaign.callPlaced" class="mt-4">
                <div class="mb-2">
                  <span>Credits Charged:
                    <span :class="fileStatusClass">{{
                      existingCampaign.credits ? existingCampaign.credits : "Not Applicable"
                    }}</span></span>
                </div>
              </div>
              <div v-if="existingCampaign && !existingCampaign.audio.approved" class="mt-4">
                <div class="mb-2">
                  <v-alert dark dense type="warning" color="green darken-2" prominent border="left"
                    class="text-caption">
                    File approval may take up to 30 minutes depending on the
                    queue. Please be patient.
                  </v-alert>
                </div>
              </div>
            </v-col>

            <!-- Right Column -->
            <v-col cols="12" md="6">
              <v-switch v-model="showCaptureInput" label="Capture Input" class="mb-5"
                :disabled="existingCampaign != null"></v-switch>

              <div v-if="showCaptureInput">
                <div style="padding-bottom: 10px;">
                  Maximum Wait For Response
                </div>
                <!-- Numeric Input for Response Timeout -->
                <v-text-field v-model="responseTimeout" label=" Timeout" type="number" suffix="Secs" outlined dense
                  class="mb-5" :disabled="existingCampaign != null"></v-text-field>
                <div style="padding-bottom: 10px;">Capture Response</div>

                <!-- Dynamic Inputs for Capturing Responses -->
                <div v-for="(response, index) in responses" :key="index">
                  <v-row v-if="index == 0" align="center" class="mb-2">
                    <v-col cols="12" md="4">
                      <v-row>
                        <v-col cols="4" md="6" style="padding-top: 20px;">
                          If press
                        </v-col>
                        <v-col cols="8" md="6">
                          <v-select v-model="response.key" :items="numberLists" item-text="key" item-value="value"
                            outlined dense :disabled="existingCampaign != null"></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-row>
                        <v-col cols="5" style="padding-top: 20px;">
                          Mark Contact As
                        </v-col>
                        <v-col cols="6">
                          <v-select v-model="response.dispoId" :items="dispoList" item-text="title" item-value="id"
                            :label="response.key == 'Default'
                              ? 'Any Other Key / Call Disconnected'
                              : 'Mark Contact As'
                              " outlined dense :disabled="existingCampaign != null"></v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row v-if="index == 1" align="center" class="mb-2">
                    <v-col cols="7" style="margin-top: -18px;">
                      Any other key / Call disconnected
                    </v-col>

                    <v-col cols="4">
                      <v-select v-model="response.dispoId" :items="dispoList" item-text="title" item-value="id" :label="response.key == 'Default'
                        ? 'Any Other Key / No Answer'
                        : 'Mark Contact As'
                        " outlined dense :disabled="existingCampaign != null"></v-select>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="existingCampaign == null">
            <div class="d-flex align-start px-3 pb-4">
              <div class="d-flex justify-center align-start">
                <v-checkbox v-model="enableTerms"></v-checkbox>
              </div>
              <div style="padding-top: 20px">
                I agree to the terms and conditions of GoDial and understand
                that if the contacts in the selected list raise any complaint
                including DND or Legal, I will be held responsible, and TRAI or
                other authorities may take necessary action against me
              </div>
            </div>
          </v-row>

          <!-- Save Button -->
          <v-btn v-if="existingCampaign == null" id="custom-disabled" color="green" dark large class="mt-5"
            :disabled="!enableTerms" @click="saveSettings">Save</v-btn>

          <v-btn v-if="existingCampaign != null && !existingCampaign.callPlaced" id="custom-disabled2" color="green"
            dark large class="mt-5" :disabled="!existingCampaign.audio.approved" @click="startVoiceBroadcast">Start
            Call</v-btn>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="pa-5" outlined>
          <!-- Reports -->
          <!-- <v-card-title class="justify-space-between">
            Reports
          </v-card-title> -->
          <v-data-table :headers="reportHeaders" :items="reportItems" class="elevation-1"></v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="primarygrad white--text" dark>
        <v-card-text style="padding: 18px 25px">
          <h3 style="margin-bottom: 5px; font-weight: 400; font-size: 16px">
            Please Wait...
          </h3>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  data: () => ({
    enableTerms: false,
    activeTab: 0,
    showCaptureInput: false,
    lists: [],
    getSelectedList: "",
    totalContacts: 0,
    fileName: "Test FileName",
    broadcastType: "audio",
    campaignName: "",
    responseTimeout: 60,
    numberLists: [
      { key: "0", value: "0" },
      { key: "1", value: "1" },
      { key: "2", value: "2" },
      { key: "3", value: "3" },
      { key: "4", value: "4" },
      { key: "5", value: "5" },
      { key: "6", value: "6" },
      { key: "7", value: "7" },
      { key: "8", value: "8" },
      { key: "9", value: "9" },
    ],
    responses: [
      { key: "1", dispoId: "" },
      { key: "Default", dispoId: "" },
    ],
    file: null,
    inputText: "",
    existingCampaign: null,
    isPlaying: false,
    progress: 0,
    timeLeft: "",
    audio: null,
    reportHeaders: [
      // { text: "ID", value: "id" },
      { text: "Campaign Name", value: "name" },
      { text: "Phone Number", value: "phone" },
      { text: "Called On", value: "calledOn" },
      { text: "Call Status", value: "callStatus" },
      { text: "Call Response", value: "callResponse" },
    ],
    reportItems: [], // Sample data should be fetched or passed in
    loading: false,
    rules: [
      (value) =>
        !value ||
        value.size < 5242880 ||
        "Avatar size should be less than 5 MB!",
    ],
    creaditsToDeduct: null,
    selectedFileName: "",
    approvedAudioList: null,
    selectedApprovedFile: null,
  }),
  computed: {
    ...mapGetters([
      "ENDPOINT",
      "fieldList",
      "teams",
      "selectedTeam",
      "user",
      "companyDetails",
    ]),
    dispoList() {
      if (!this.$store.getters.dispoList) return [];
      return this.$store.getters.dispoList.filter((d) => {
        return (
          !d.system ||
          (d.system && d.title == "DNC") ||
          (d.system && d.title == "CALLBACK")
        );
      });
    },
    fileStatusClass() {
      return !this.existingCampaign.audio.approved ? "red-text" : "green-text";
    },
    // isButtonDisabled() {
    //   if (!this.file) return true;
    //   return this.file.size >= 2000000;
    // },
  },
  async created() {
    await this.fetchLists();
    await this.getAudioList();
    console.log("a")

    if (this.$route.params.id != null) {
      const checkAudioApproval = await this.$http.post(
        `${this.ENDPOINT}/voiceBroadcasts/getAudioFile`,
        {
          campId: this.$route.params.id,
        }
      );
      const getCampaign = await this.$http.get(
        `${this.ENDPOINT}/voiceBroadcasts/${this.$route.params.id}?filter[include]=audio`,
        {
          campId: this.$route.params.id,
        }
      );
      this.existingCampaign = getCampaign.body;
      this.campaignName = this.existingCampaign.title;
      this.fileName = this.existingCampaign.fileName;
      this.showCaptureInput = this.existingCampaign.collectResponse;
      this.getSelectedList = this.existingCampaign.listId;
      this.broadcastType = this.existingCampaign.isText ? "text" : "audio";
      this.inputText = this.existingCampaign.isText
        ? this.existingCampaign.inputText
        : "";
      this.audio = new Audio(
        `https://storage.googleapis.com/godial-enterprise-export/vbAudio/${this.existingCampaign.fileName}`
      );
      this.responses = this.existingCampaign.responseSettings;

      this.audio.addEventListener("timeupdate", this.updateProgress);
      this.audio.addEventListener("loadedmetadata", () => {
        this.calculateTimeLeft(this.audio.duration);
      });
      this.audio.addEventListener("ended", this.resetAudio);

      await this.viewReports();

      await this.calculateCredits();
    }
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener("timeupdate", this.updateProgress);
      this.audio.removeEventListener("loadedmetadata", this.calculateTimeLeft);
      this.audio.removeEventListener("ended", this.resetAudio);
    }
  },

  methods: {
    changeSelectedAudio() {
      // this.selectedApprovedFile = item;
      console.log("this.selectedApprovedFile: ", this.selectedApprovedFile);
      this.audio.pause();
      this.resetAudio();
      this.audioFileGenerate(this.selectedApprovedFile)
    },
    async getAudioList() {
      const resp = await this.$http.get(
        `${this.ENDPOINT}/audioLists?filter[where][companyId]=${this.companyDetails.id}`
      );
      console.log("Audio List:", resp.body);
      this.approvedAudioList = resp.body.filter(
        (audio) => audio.approved === true
      );

      console.log("this.approvedAudioList", this.approvedAudioList);

      if (this.approvedAudioList.length > 0) {
        this.selectedApprovedFile = this.approvedAudioList[0];
        this.audioFileGenerate(this.selectedApprovedFile);
      }
    },
    audioFileGenerate(selectedFile) {
      console.log("selectedFile", selectedFile);

      this.audio = new Audio(
        `https://storage.googleapis.com/godial-enterprise-export/vbAudio/${selectedFile.fileName}`
      );

      this.audio.addEventListener("timeupdate", this.updateProgress);
      this.audio.addEventListener("loadedmetadata", () => {
        this.calculateTimeLeft(this.audio.duration);
      });
      this.audio.addEventListener("ended", this.resetAudio);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    checkTime() {
      const now = moment().tz("Asia/Kolkata");
      const hour = now.hour();
      const minute = now.minute();

      // Check if the time is between 6 PM and 9 AM
      if (
        (hour >= 18 && hour <= 23) ||
        (hour >= 0 && hour < 9) ||
        (hour === 9 && minute === 0)
      ) {
        console.log("The current time in India is between 6 PM and 9 AM.");
        return true;
      } else {
        console.log("The current time in India is not between 6 PM and 9 AM.");
        return false;
      }
    },
    async viewReports() {
      var resp = await this.$http.get(
        `${this.ENDPOINT}/voiceBroadcastLogs?filter[where][campaignId]=${this.$route.params.id}&filter[include]=voiceBroadcast`
      );

      // var response = await this.$http.get(
      //   `${this.ENDPOINT}/voiceBroadcastLogs`
      //   {
      //     filter: {
      //       where: {
      //         //campaignId: this.$route.params.campaignItem.id,
      //         // companyId: this.$store.state.companyDetails.id,
      //       },
      //     },
      //   }
      // );
      const modifiedData = resp.data.map((item) => {
        return {
          ...item,
          calledOn: moment(item.calledOn).format("YYYY-MM-DD HH:mm"),
          callStatus: item.callStatus == "1" ? "Answered" : "Not Answered",
        };
      });
      console.log("modifiedData: ", modifiedData);

      this.reportItems = modifiedData;
    },
    togglePlay() {
      if (this.audio.paused) {
        this.audio.play();
        this.isPlaying = true;
      } else {
        this.audio.pause();
        this.isPlaying = false;
      }
    },
    updateProgress() {
      const currentTime = this.audio.currentTime;
      const duration = this.audio.duration;
      this.progress = (currentTime / duration) * 100;
      this.calculateTimeLeft(duration - currentTime);
    },
    calculateTimeLeft(timeLeft) {
      const mins = Math.floor(timeLeft / 60);
      const secs = Math.floor(timeLeft % 60);
      this.timeLeft = `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    },
    resetAudio() {
      this.audio.currentTime = 0;
      this.isPlaying = false;
      this.progress = 0;
      this.calculateTimeLeft(this.audio.duration);
    },
    async calculateCredits() {
      // const totalContact = await this.$http.get(
      //   `${this.ENDPOINT}/lists/${this.existingCampaign.listId}/contacts/count`
      // );
      // console.log("totalContact: ", totalContact.body);
      // return totalContact.body;

      var dispos = _.map(this.dispoList, (d) => {
        return d.title;
      });

      this.$http
        .post(`${this.$store.state.ENDPOINT}/contacts/byFilterV4`, {
          queryCase: "count",
          predicates: [],
          matchType: "",
          lists: [this.getSelectedList],
          dispos: [],
        })
        .then((res) => {
          this.totalContacts = res.body.count;
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    async startVoiceBroadcast() {
      if (
        typeof this.companyDetails.kycDetails == "undefined" ||
        !this.companyDetails.kycDetails.kycVerified
      ) {
        return this.$swal({
          type: "error",
          text: "Please complete your KYC from settings.",
        });
      }
      const self = this;

      try {
        const isSixPm = self.checkTime();

        console.log("isSixPm", isSixPm);

        if (isSixPm) {
          return this.$swal({
            type: "error",
            text:
              "You can't place broadcast calls within 6pm to 9am due to telecom rules",
          });
        }

        self.loading = true;

        const checkAudioApproval = await this.$http.post(
          `${this.ENDPOINT}/voiceBroadcasts/getAudioFile`,
          {
            campId: this.$route.params.id,
          }
        );

        if (checkAudioApproval.body.approved) {
          var response = await this.$http.post(
            `${this.ENDPOINT}/voiceBroadcasts/startVoiceBroadcast`,
            {
              campId: this.$route.params.id,
            }
          );
          console.log("response: ", response.body);

          self.loading = false;
          if (response.body.vnsResponse.status == "failure") {
            return this.$swal({
              type: "error",
              text: response.body.vnsResponse.value,
            });
          }
          return this.$swal({
            type: "success",
            text:
              "Voice Broadcast placed successfully.It may takes upto 30 min to place call.Please check the report for update.",
          });
        } else {
          self.loading = false;
          return this.$swal({
            type: "error",
            text: "Audio file is not approved. Please try after some time.",
          });
        }
      } catch (error) {
        this.loading = false;
        console.log("error: ", error.data.error.message);
        return this.$swal({
          type: "error",
          text: error.data.error.message,
        });
      }
    },
    backtoCampaign() {
      this.$router.replace({ path: "/survey-in" });
    },
    getTeamIds() {
      var teams = [];
      if (this.selectedTeam == "allTeams") {
        teams = _.map(this.teams, "id");
      } else {
        teams.push(this.selectedTeam);
      }

      teams = _.without(teams, "allTeams");

      return teams;
    },
    async fetchLists() {
      try {
        this.lists = [];
        var response = [];
        var teamIds = this.getTeamIds();

        // Teams sections
        response = await this.$http.post(`${this.ENDPOINT}/lists/fetch`, {
          teams: teamIds,
        });
        this.lists = response.body;
        if (this.lists.length < 1) {
          return;
        }

        this.getSelectedList = this.lists[0].id;

        this.calculateCredits();
      } catch (e) {
        console.log("error in list fetch", e);
      }
    },
    addResponse() {
      this.responses.push({ key: "", dispoId: "" });
    },
    removeResponse(index) {
      this.responses.splice(index, 1);
    },
    onFileSelect(event) {
      const file = event.target.files[0];
      this.file = file;
      if (file) {
        this.selectedFileName = file.name;
        console.log("file: ", file);

        console.log("this.selectedFileName: ", this.selectedFileName);
      }
    },
    async handleFileUpload() {
      try {
        const self = this;

        if (this.broadcastType == "audio") {
          const formData = new FormData();
          formData.append("file", self.file);
          const fileResponse = await self.$http.post(
            `${self.ENDPOINT}/voiceBroadcasts/fileUpload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          return fileResponse.body;
        } else {
          const fileResponse = await self.$http.post(
            `${self.ENDPOINT}/voiceBroadcasts/getTts`,
            {
              text: this.inputText,
            }
          );
          return fileResponse.body;
        }
      } catch (error) {
        return error.body;
      }
    },
    async saveSettings() {
      const self = this;
      try {
        // Audio validation
        if (this.broadcastType === "audio") {
          if (!this.file) {
            return this.showError("Please select a file.");
          }

          if (this.file.size > 5242880) {
            // 5 MB in bytes
            return this.showError("File size exceeds 5 MB limit.");
          }

          const audioUrl = URL.createObjectURL(this.file);
          const audio = new Audio(audioUrl);

          const audioValidationPromise = new Promise((resolve, reject) => {
            audio.onloadedmetadata = () => {
              URL.revokeObjectURL(audioUrl);
              if (audio.duration > 60) {
                // 1 minute in seconds
                reject("Audio duration exceeds 1 minute limit.");
              } else {
                resolve();
              }
            };

            audio.onerror = () => {
              URL.revokeObjectURL(audioUrl);
              reject("Error loading audio file for duration check.");
            };

            audio.src = audioUrl;
          });

          try {
            await audioValidationPromise;
          } catch (error) {
            return this.showError(error);
          }
        }

        // Text validation
        if (this.broadcastType === "text" && !this.inputText.length) {
          return this.showError("Please enter message text to continue.");
        }

        if (!this.campaignName) {
          return this.showError("Please enter a title to continue.");
        }

        self.loading = true;
        let fileResp = { fileName: "", thirdPartyFileId: "" };

        if (this.broadcastType === "audio" || this.broadcastType === "text") {
          fileResp = await self.handleFileUpload();
          console.log("file response audio vb: ", fileResp)
          if (!fileResp.fileName) {
            self.loading = false;
            return this.showError(
              fileResp.error.details || fileResp.error.message
            );
          }
        }

        const dispo = self.dispoList;
        const busyItem = dispo.find((item) => item.title === "BUSY");
        self.responses.push({ key: "Not Answered", dispoId: busyItem.id });

        const data = {
          listId: self.getSelectedList,
          title: self.campaignName,
          fileName:
            this.broadcastType === "audio" || this.broadcastType === "text"
              ? fileResp.fileName
              : this.selectedApprovedFile.fileName,
          thirdPartyFileId:
            this.broadcastType === "audio" || this.broadcastType === "text"
              ? fileResp.thirdPartyFileId
              : this.selectedApprovedFile.thirdPartyFileId,
          collectResponse: self.showCaptureInput,
          responseSettings: self.showCaptureInput ? self.responses : [],
          isText: this.broadcastType == "audio" ? false : (this.broadcastType == 'audioList' ? false : true),
          inputText: this.broadcastType === "audio" ? "" : this.inputText,
        };

        const response = await self.$http.post(
          `${self.ENDPOINT}/voiceBroadcasts/createCampaign`,
          data
        );

        self.loading = false;
        if (response.body.id) {
          this.$swal({
            type: "success",
            text: "Voice Broadcast successfully created",
          }).then(() => {
            this.$router.push({ path: "/survey-in" });
          });
        }
      } catch (error) {
        self.loading = false;
        console.log("error: ", error);
        this.showError(error.message ? error.message : error.data.error.message);
      }
    },

    showError(message) {
      this.$swal({
        type: "error",
        text: message,
      }).then(() => {
        this.responses = [
          { key: "1", dispoId: "" },
          { key: "Default", dispoId: "" },
        ]
      });
    },
  },
};
</script>

<style scoped>
.audio-left {
  width: 60%;
}

.audio-right {
  width: 40%;
}

.audio-container {
  padding: 0.8rem;
  background: rgba(55, 207, 55, 0.25);
  box-shadow: 0 8px 8px 0 rgba(206, 208, 235, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.custom-font {
  font-size: 0.7rem;
}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

#custom-disabled.v-btn--disabled {
  background-color: rgba(0, 154, 0, 0.5) !important;
}

#custom-disabled2.v-btn--disabled {
  background-color: rgba(0, 154, 0, 0.5) !important;
}

.cursor {
  cursor: pointer;
}
</style>
